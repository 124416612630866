import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const ChatWidgetHandler = () => {
    const { isFcWidgetLoaded } = useSelector((state) => state.userAgentDetails);
    const location = useLocation();
    const pathsToHideWidget = ["/storage", "/playground", "/checkout"];

    useEffect(() => {
        const hideWidgetOnMobileSpecificPage = () => {
            const currentPath = location?.pathname;
            const shouldHide = pathsToHideWidget.some((path) => currentPath.includes(path));
            const screenWidth = window.innerWidth;

            if (screenWidth < 768 && shouldHide) {
                window?.fcWidget?.hide();
            } else {
                window?.fcWidget?.show();
            }
        };

        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const currentPath = location?.pathname;
            const shouldHide = pathsToHideWidget.some((path) => currentPath.includes(path));

            if (isFcWidgetLoaded) {
                if (screenWidth < 768 && shouldHide) {
                    window?.fcWidget?.hide();
                } else {
                    window?.fcWidget?.show();
                }
            }
        };

        window?.fcWidget?.on("widget:loaded", () => {
            hideWidgetOnMobileSpecificPage();
        });

        hideWidgetOnMobileSpecificPage();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            window?.fcWidget?.off("widget:loaded");
        };
    }, [isFcWidgetLoaded, location?.pathname]);

    return null;
};

export default ChatWidgetHandler;
